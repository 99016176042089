import { Component, OnInit, ViewEncapsulation, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { UserSettings } from './model/UserSettings';
import { RouterOutlet } from '@angular/router';
import { fadeIn } from './shared/animations/fade-in';
import { trigger } from '@angular/animations';
import { AppSeoService } from './shared-services/app-seo.service';
import { CurrentCustomerService } from './shared-services/current-customer.service';
import { FooterComponent } from './shared/footer/footer.component';
import { NavBarComponent } from './shared/nav-bar/nav-bar.component';

@Component({
    selector: 'mein-terrassendach24-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('fadeIn', fadeIn())
    ],
    standalone: true,
    imports: [NavBarComponent, RouterOutlet, FooterComponent]
})
export class AppComponent implements OnInit {
  showScroll: boolean;
  showScrollHeight = 300;
  hideScrollHeight = 10;
  userSettings: UserSettings = null;


  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) {
  //     this.showScroll = true;
  //   }
  //   else if (this.showScroll && (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) {
  //     this.showScroll = false;
  //   }
  // }

  public constructor(private appSeoService: AppSeoService, private cookieService: CookieService, private ccc: CurrentCustomerService,
    ) {
  }

  ngOnInit(): void {
    this.appSeoService.boot();
    //this.initUserSettings(); 
  }

  scrollToTop() 
  { 
    (function smoothscroll() 
    { var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; 
      if (currentScroll > 0) 
      {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
  }

  getAnimationData(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  private initUserSettings() {
    this.userSettings = this.cookieService.getObject('userSettings') as UserSettings;
  }
}


