import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentCustomerService } from './shared-services/current-customer.service';
import { CurrentProductService } from './shared-services/current-product.service';
import { AppSettingsService } from './shared-services/app-settings-service';
import { CacheInterceptorService } from './shared-services/cache-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomerDataService } from './shared-services/customer-data-service';
import { ProductControlsService } from './shared-services/product-controls.service';
import { AuthorizeService } from './api-authorization/authorize.service';
import { EasyCreditService } from './shared-services/easy-credit-service';
import { OfferService } from './shared-services/offer-service';
import { ProductsDataService } from './shared-services/products-data.service';
import { SitesService } from './shared-services/sites.service';
import { ProductCategoriesDataService } from './shared-services/product-categories-data.service';

@NgModule({
  declarations: [],
  providers: [
    AuthorizeService,
    CustomerDataService,
    CurrentCustomerService,
    EasyCreditService,
    ProductControlsService,
    ProductsDataService,
    ProductCategoriesDataService,
    CurrentProductService,
    OfferService,
    AppSettingsService,
    SitesService,
  ],
  imports: [
    CommonModule
  ]
})
export class SharedServicesModule {
  static forRoot(): ModuleWithProviders<SharedServicesModule> {
    return {
      ngModule: SharedServicesModule,
      providers: [
        AuthorizeService,
        CustomerDataService,
        CurrentCustomerService,
        EasyCreditService,
        ProductControlsService,
        ProductsDataService,
        ProductCategoriesDataService,
        CurrentProductService,
        OfferService,
        AppSettingsService,
        SitesService,
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptorService, multi: true }
      ]
    }
  }
}
