/// <reference types="@angular/localize" />

import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LOCALE_ID, enableProdMode, importProvidersFrom } from "@angular/core";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  RouteReuseStrategy,
  provideRouter,
  withInMemoryScrolling,
} from "@angular/router";
import { GALLERY_CONFIG, GalleryConfig } from 'ng-gallery';
import { LIGHTBOX_CONFIG, LightboxConfig } from 'ng-gallery/lightbox';
import { CookieModule } from "ngx-cookie";
import { ApiAuthorizationModule } from "./app/api-authorization/api-authorization.module";
import { AuthorizeInterceptor } from "./app/api-authorization/authorize.interceptor";
import { AppComponent } from "./app/app.component";
import { APP_ROUTES } from "./app/app.routes";
import { CustomRouteReuseStrategy } from "./app/common/custom-route-reuse-strategy";
import { SharedServicesModule } from "./app/shared-services.module";
import { SharedMinimumModule } from "./app/shared/shared.minimum.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: "enabled",
};

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(APP_ROUTES, inMemoryScrollingFeature),
    importProvidersFrom(
      BrowserModule,
      CookieModule.withOptions(),
      ApiAuthorizationModule.forRoot(),
      SharedServicesModule.forRoot(),
      SharedMinimumModule,
    ),
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "de" },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    provideAnimations(),
    {
      provide: GALLERY_CONFIG,
      useValue: {  
        loadingStrategy: 'default',
        loadingAttr: 'lazy',
        itemAutosize: true,      
        autoHeight: true,
        imageSize: 'cover',
        thumbWidth: 120,
        thumbHeight: 80,
        nav: true,
        counter: false
      } as GalleryConfig
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
          panelClass: 'lightbox-panel',

      } as LightboxConfig
    }
  ],
}).catch((err) => console.log(err));
