<div class="container-xl px-0">
  <div class="row g-0">
    <div class="col px-0 mt-4">
      <ngb-carousel
        [animation]="true"
        [showNavigationArrows]="true"
        [showNavigationIndicators]="true"
        [pauseOnHover]="true">
        <ng-template ngbSlide>
          <a routerLink="/terrassenueberdachung/wandanschluss/premium-xl">
            <div class="carousel-img-wrapper">
              <img
                ngSrc="main-page/slider/slider-01-terrassendach-mein-terrassendach24.jpg"
                fill
                priority />
            </div>
          </a>
          <!-- <div class="carousel-caption">
      <h1 class="display-4">Das Terrassendach</h1>
      <h2 class="display-4">Einfach gut geschützt</h2>
      <p> <a mat-flat-button color="primary" class="carousel-button mt-3 py-2 rounded-0"
          routerLink="/terrassenueberdachung/wandanschluss/premium-xl">Zu den Angeboten</a></p>
    </div> -->
        </ng-template>

        <ng-template ngbSlide>
          <a routerLink="/terrassenueberdachung/freistehend">
            <div class="carousel-img-wrapper">
              <img
                class="w-100"
                ngSrc="main-page/slider/slider-02-freistehende-konstruktion-mein-terrassendach24.jpg"
                fill
                priority />
            </div>
          </a>
        </ng-template>

        <ng-template ngbSlide>
          <a routerLink="/terrassenueberdachung/schiebedach">
            <div class="carousel-img-wrapper">
              <img
                class="w-100"
                ngSrc="main-page/slider/slider-04-schiebedach-mein-terrassendach24.jpg"
                fill
                priority />
            </div>
          </a>
        </ng-template>
        <ng-template ngbSlide>
          <a routerLink="/terrassenueberdachung/wandanschluss/superior-xxl">
            <div class="carousel-img-wrapper">
              <img
                class="w-100"
                ngSrc="main-page/slider/slider-05-xxl-ueberdachung-mein-terrassendach24.jpg"
                fill
                priority />
            </div>
          </a>
        </ng-template>
      </ngb-carousel>

      <itcw-component-page-header class="mt-4 mb-3"
        >Ihr Fachhändler für Terrassenüberdachungen</itcw-component-page-header
      >

      <div class="row gx-0 gx-sm-4">
        <div class="col hover-zoom">
          <a
            routerLink="/terrassenueberdachung/wandanschluss"
            class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0 overflow-hidden">
            <img
              class="card-img-top rounded-0"
              [ngSrc]="imgSrcWandanschluss"
              fill
              title="Terrassenüberdachungen für einen Wandanschluss"
              alt="Terrassenüberdachungen für einen Wandanschluss"
              (mouseover)="imgSrcWandanschluss = imgSrcWandanschlussHover"
              (mouseout)="imgSrcWandanschluss = imgSrcWandanschlussDefault" />
          </a>
        </div>
        <div class="col hover-zoom">
          <a
            routerLink="/terrassenueberdachung/freistehend"
            class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0 overflow-hidden">
            <img
              class="card-img-top rounded-0"
              [ngSrc]="imgSrcFreistehend"
              fill
              title="Freistehende Terrassenüberdachungen"
              alt="Freistehende Terrassenüberdachungen"
              (mouseover)="imgSrcFreistehend = imgSrcFreistehendHover"
              (mouseout)="imgSrcFreistehend = imgSrcFreistehendDefault" />
          </a>
        </div>
        <div class="col hover-zoom">
          <a
            routerLink="/terrassenueberdachung/schiebedach"
            class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0 overflow-hidden">
            <img
              class="card-img-top rounded-0"
              [ngSrc]="imgSrcSchiebedach"
              fill
              title="Terrassenüberdachungen mit Schiebedach"
              alt="Terrassenüberdachungen mit Schiebedach"
              (mouseover)="imgSrcSchiebedach = imgSrcSchiebedachHover"
              (mouseout)="imgSrcSchiebedach = imgSrcSchiebedachDefault" />
          </a>
        </div>
        <div class="col hover-zoom">
          <a
            routerLink="/terrassenueberdachung/flachdach"
            class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0 overflow-hidden">
            <img
              class="card-img-top rounded-0"
              [ngSrc]="imgSrcFlachdach"
              fill
              title="Terrassenüberdachungen als Flachdach"
              alt="Terrassenüberdachungen als Flachdach"
              (mouseover)="imgSrcFlachdach = imgSrcFlachdachHover"
              (mouseout)="imgSrcFlachdach = imgSrcFlachdachDefault" />
          </a>
        </div>
        <div class="col hover-zoom">
          <a
            routerLink="/terrassenueberdachung/gartenzimmer"
            class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0 overflow-hidden">
            <img
              class="card-img-top rounded-0"
              [ngSrc]="imgSrcGartenzimmer"
              fill
              title="Terrassenüberdachungen als Gartenzimmer"
              alt="Terrassenüberdachungen als Gartenzimmer"
              (mouseover)="imgSrcGartenzimmer = imgSrcGartenzimmerHover"
              (mouseout)="imgSrcGartenzimmer = imgSrcGartenzimmerDefault" />
          </a>
        </div>
        <div class="col hover-zoom">
          <a
            routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs"
            class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0 overflow-hidden">
            <img
              class="card-img-top rounded-0"
              [ngSrc]="imgSrcWandanschlussKompaktXXS"
              fill
              title="Terrassenüberdachungen als Wandanschluss Kompakt XXS"
              alt="Terrassenüberdachungen als Kompakt XXS"
              (mouseover)="
                imgSrcWandanschlussKompaktXXS =
                  imgSrcWandanschlussKompaktXXSHover
              "
              (mouseout)="
                imgSrcWandanschlussKompaktXXS =
                  imgSrcWandanschlussKompaktXXSDefault
              " />
          </a>
        </div>
      </div>

      <!-- <a routerLink="/terrassenueberdachung/wandanschluss/einsteiger-xs" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0"  [ngSrc]="imgSrcWandanschluss" fill[lazyLoad]="imgSrcWandanschlussEinsteigerXS" title="Terrassenüberdachungen als Wandanschluss Einsteiger XS"
      alt="Terrassenüberdachungen als Einsteiger XS" (mouseover)="imgSrcWandanschlussEinsteigerXS = imgSrcWandanschlussEinsteigerXSHover"
      (mouseout)="imgSrcWandanschlussEinsteigerXS = imgSrcWandanschlussEinsteigerXSDefault">
  </a> -->

      <!-- <div class="card-deck"> -->
      <!-- <a routerLink="/terrassenueberdachung/lamellendach" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0"  [ngSrc]="imgSrcWandanschluss" fill[lazyLoad]="imgSrcLamellendach" title="Terrassenüberdachungen mit Lamellendach"
      alt="Terrassenüberdachungen mit Lamellendach" (mouseover)="imgSrcLamellendach = imgSrcLamellendachHover"
      (mouseout)="imgSrcLamellendach = imgSrcLamellendachDefault">
  </a> -->

      <!-- <a routerLink="/terrassenueberdachung/wandanschluss/kompakt-xxs" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0"  [ngSrc]="imgSrcWandanschluss" fill[lazyLoad]="imgSrcWandanschlussKompaktXXS" title="Terrassenüberdachungen als Wandanschluss Kompakt XXS"
      alt="Terrassenüberdachungen als Kompakt XXS" (mouseover)="imgSrcWandanschlussKompaktXXS = imgSrcWandanschlussKompaktXXSHover"
      (mouseout)="imgSrcWandanschlussKompaktXXS = imgSrcWandanschlussKompaktXXSDefault">
  </a>

  <a routerLink="/terrassenueberdachung/wandanschluss/superior-xxl" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0"  [ngSrc]="imgSrcWandanschluss" fill[lazyLoad]="imgSrcSuperior" title="Superior Terrassenüberdachung"
      alt="Superior Terrassenüberdachung" (mouseover)="imgSrcSuperior = imgSrcSuperiorHover"
      (mouseout)="imgSrcSuperior = imgSrcSuperiorDefault">
  </a>

  <a routerLink="/terrassenueberdachung/flachdach/xxl" class="teaser-card-product card mb-3 rounded-0 mat-elevation-z0">
    <img class="card-img-top rounded-0"  [ngSrc]="imgSrcWandanschluss" fill[lazyLoad]="imgSrcFlachdachXXL" title="Terrassenüberdachungen als Flachdach XXL"
      alt="Terrassenüberdachungen als Kompakt XXS" (mouseover)="imgSrcFlachdachXXL = imgSrcFlachdachXXLHover"
      (mouseout)="imgSrcFlachdachXXL = imgSrcFlachdachXXLDefault">
  </a> -->

      <!-- </div> -->

      <itcw-component-page-header class="mt-4"
        >Qualität und Sortiment</itcw-component-page-header
      >

      <div class="my-2 container-fluid px-0">
        <div class="row g-0">
          <div class="col-sm teaser-blue me-sm-3 me-0 my-2 position-relative">
            <img
              ngSrc="main-page/teaser/Produktmerkmale-hg-Mein-Terrassendach24.jpg"
              fill
              title="Produktmerkmale"
              alt="Produktmerkmale" />
            <div class="d-flex flex-column position-relative">
              <div class="">
                <h2 class="header-base teaser-header">
                  Unsere Produktmerkmale
                </h2>
                <hr class="teaser-header-line" />
              </div>
              <div class="ms-auto me-auto">
                <mat-list class="teaser-list-white">
                  <mat-list-item>
                    <mat-icon matListItemIcon>done</mat-icon>Hergestellt in
                    Deutschland
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon matListItemIcon>done</mat-icon>Erweiterbares
                    System
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon matListItemIcon>done</mat-icon>Terrassendach
                    Bausätze
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon matListItemIcon>done</mat-icon>Innovative Lösungen
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon matListItemIcon>done</mat-icon>Zeitloses Design
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon matListItemIcon>done</mat-icon>Starke Profile
                  </mat-list-item>
                  <mat-list-item>
                    <mat-icon matListItemIcon>done</mat-icon>Bis zu
                    Schneelastzone 3
                  </mat-list-item>
                </mat-list>
              </div>
              <div class="ms-auto mt-auto me-3 mb-4">
                <a
                  class="teaser-more-button"
                  routerLink="/ueber-uns/produktmerkmale"
                  >mehr
                  <span class="teaser-more-button-dots">
                    &bull;&bull;&bull;</span
                  ></a
                >
              </div>
            </div>
          </div>
          <div class="col-sm teaser-grey ms-sm-3 ms-0 my-2 position-relative">
            <img
              ngSrc="main-page/teaser/Onlinekatalog-hg-Mein-Terrassendach24.jpg"
              fill
              title="Onlinekatalog"
              alt="Onlinekatalog"
              priority />
            <div class="d-flex flex-column position-relative">
              <div class="">
                <h2 class="header-base teaser-header">Onlinekatalog</h2>
                <hr class="teaser-header-line" />
              </div>
              <div class="ms-auto me-auto">
                <img
                  style="max-height: 250px"
                  ngSrc="main-page/teaser/Onlinekatalog-Katalog-Mein-Terrassendach24.png"
                  width="274"
                  height="250"
                  priority />
              </div>
              <div class="ms-auto mt-auto me-3 mb-4">
                <a
                  class="teaser-more-button"
                  target="_blank"
                  href="https://view.publitas.com/planung-nord-gmbh/mein-terrassendach24-de-katalog-gesamtsortiment/page/1"
                  >mehr
                  <span class="teaser-more-button-dots">
                    &bull;&bull;&bull;</span
                  ></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <itcw-component-page-header class="mt-4"
        >Service und Vorteile</itcw-component-page-header
      >

      <div class="my-2 container-fluid px-0">
        <div class="row g-0">
          <div class="col-md teaser-blue me-md-3 me-0 my-2">
            <div class="d-flex flex-column" style="height: 100%">
              <div class="">
                <h2 class="header-base teaser-header">Beratung & Planung</h2>
                <hr class="teaser-header-line" />
              </div>
              <div class="ms-auto me-auto">
                <div
                  class="pt-5 text-center teaser-rounded-text rounded-circle">
                  Von Ihrer Idee<br />bis zum fertigen<br />Projekt
                </div>
              </div>
              <div class="ms-auto mt-auto me-3 mb-4">
                <a
                  class="teaser-more-button"
                  routerLink="/ueber-uns/beratung-und-planung"
                  >mehr
                  <span class="teaser-more-button-dots">
                    &bull;&bull;&bull;</span
                  ></a
                >
              </div>
            </div>
          </div>

          <div class="col-md teaser-grey mx-md-3 mx-0 my-2 position-relative">
            <img
              ngSrc="main-page/teaser/Montageservice-hg-Mein-Terrassendach24.jpg"
              fill
              priority
              alt="Montageservice"
              title="Montageservice" />
            <div
              class="d-flex flex-column teaser-product-assembly-service-bg position-relative">
              <div class="">
                <h2 class="header-base teaser-header text-black">
                  Montageservice
                </h2>
                <hr class="teaser-header-line" />
              </div>
              <div
                class="ms-0 mt-4 py-4 px-4 me-auto text-center product-assembly-service-text">
                Erfahrene Monteure<br />
                deutschlandweit <br />
                verfügbar<br />
              </div>
              <div class="ms-auto mt-auto me-3 mb-4">
                <a
                  class="teaser-more-button teaser-more-button-dark text-dark"
                  routerLink="/ueber-uns/montageservice"
                  >mehr
                  <span class="teaser-more-button-dots">
                    &bull;&bull;&bull;</span
                  ></a
                >
              </div>
            </div>
          </div>

          <div class="col-md teaser-blue ms-md-3 ms-0 my-2">
            <div class="d-flex flex-column" style="height: 100%">
              <div class="">
                <h2 class="header-base teaser-header">Garantieversprechen</h2>
                <hr class="teaser-header-line" />
              </div>
              <div class="ms-auto me-auto">
                <div
                  class="pt-5 text-center teaser-rounded-text rounded-circle">
                  10<br />Jahre
                </div>
              </div>
              <div class="ms-auto mt-auto me-3 mb-4">
                <a
                  class="teaser-more-button"
                  routerLink="/ueber-uns/garantiebedingungen"
                  >mehr
                  <span class="teaser-more-button-dots">
                    &bull;&bull;&bull;</span
                  ></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
