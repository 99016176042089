import { Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatList, MatListItem, MatListItemIcon } from '@angular/material/list';
import { ComponentPageHeaderComponent } from '../shared/component-page-header/component-page-header.component';
import { NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';
import { NgbCarousel, NgbSlide } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'itcw-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss'],
    standalone: true,
    imports: [
        NgbCarousel,
        NgbSlide,
        RouterLink,
        NgOptimizedImage,
        ComponentPageHeaderComponent,
        MatList,
        MatListItem,
        MatIcon,
        MatListItemIcon,
    ],
})
export class MainPageComponent implements OnInit {

  imgSrcWandanschluss: string = 'main-page/categories/kategorie-banner-wandanschluss.jpg';
  imgSrcWandanschlussDefault: string = 'main-page/categories/kategorie-banner-wandanschluss.jpg';
  imgSrcWandanschlussHover: string = 'main-page/categories/kategorie-banner-wandanschluss-hover.jpg';

  imgSrcFreistehend: string = 'main-page/categories/kategorie-banner-freistehend.jpg';
  imgSrcFreistehendDefault: string = 'main-page/categories/kategorie-banner-freistehend.jpg';
  imgSrcFreistehendHover: string = 'main-page/categories/kategorie-banner-freistehend-hover.jpg';

  imgSrcSchiebedach: string = 'main-page/categories/kategorie-banner-schiebedach.jpg';
  imgSrcSchiebedachDefault: string = 'main-page/categories/kategorie-banner-schiebedach.jpg';
  imgSrcSchiebedachHover: string = 'main-page/categories/kategorie-banner-schiebedach-hover.jpg';

  imgSrcFlachdach: string = 'main-page/categories/kategorie-banner-flachdachxl.jpg';
  imgSrcFlachdachDefault: string = 'main-page/categories/kategorie-banner-flachdachxl.jpg';
  imgSrcFlachdachHover: string = 'main-page/categories/kategorie-banner-flachdachxl-hover.jpg';

  imgSrcGartenzimmer: string = 'main-page/categories/kategorie-banner-gartenzimmer.jpg';
  imgSrcGartenzimmerDefault: string = 'main-page/categories/kategorie-banner-gartenzimmer.jpg';
  imgSrcGartenzimmerHover: string = 'main-page/categories/kategorie-banner-gartenzimmer-hover.jpg';


  imgSrcLamellendach: string = 'main-page/categories/kategorie-banner-lamellendach.jpg';
  imgSrcLamellendachDefault: string = 'main-page/categories/kategorie-banner-lamellendach.jpg';
  imgSrcLamellendachHover: string = 'main-page/categories/kategorie-banner-lamellendach-hover.jpg';

  imgSrcWandanschlussEinsteigerXS: string = 'main-page/categories/kategorie-banner-einsteigerxs.jpg';
  imgSrcWandanschlussEinsteigerXSDefault: string = 'main-page/categories/kategorie-banner-einsteigerxs.jpg';
  imgSrcWandanschlussEinsteigerXSHover: string = 'main-page/categories/kategorie-banner-einsteigerxs-hover.jpg';

  imgSrcWandanschlussKompaktXXS: string = 'main-page/categories/kategorie-banner-kompaktxxs.jpg';
  imgSrcWandanschlussKompaktXXSDefault: string = 'main-page/categories/kategorie-banner-kompaktxxs.jpg';
  imgSrcWandanschlussKompaktXXSHover: string = 'main-page/categories/kategorie-banner-kompaktxxs-hover.jpg';

  imgSrcSuperior: string = 'main-page/categories/kategorie-banner-superiorxxl.jpg';
  imgSrcSuperiorDefault: string = 'main-page/categories/kategorie-banner-superiorxxl.jpg';
  imgSrcSuperiorHover: string = 'main-page/categories/kategorie-banner-superiorxxl-hover.jpg';

  imgSrcFlachdachXXL: string = 'main-page/categories/kategorie-banner-flachdachxxl.jpg';
  imgSrcFlachdachXXLDefault: string = 'main-page/categories/kategorie-banner-flachdachxxl.jpg';
  imgSrcFlachdachXXLHover: string = 'main-page/categories/kategorie-banner-flachdachxxl-hover.jpg';
  constructor() { }

  ngOnInit() {
  }

}


// export function fadeInImg() {
//   return [
//     transition('* => *', [

//       query(
//         "img[src]",
//         [
//           style({ opacity: 0 }),
//           animate(".8s cubic-bezier(0.4, 0.0, 0.2, 1)", style({ opacity: 1 }))
//         ],
//         {
//           optional: true
//         }
//       )
//     ])
//   ];
// }

